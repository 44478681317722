<template>
  <div class="grid row">
    <div class="flex xs12">
      <va-card title="Career" class="fill-height">
        <div>
          <div class="row">
            <div class="flex xs12">
              <va-tabs v-model="tabValue" style="width: 100%; min-width: 250px; margin-bottom: 25px;">
                <va-tab
                  v-for="title in tabTitles"
                  :key="title"
                  :class="{'is-invalid': isTabInvalid(title)}"
                >
                  {{title}}
                </va-tab>
              </va-tabs>
              <div class="flex xs12" v-if="tabValue === 0">
                <va-card class="mb-3" title="Main screen background image *">
                  <div v-if="mainBgImgExisting">
                    <a style="display: block;" target="_blank" :href="mainBgImgExisting"><img style="max-width: 400px;" :src="mainBgImgExisting"></a>
                    <div><va-button small color="danger" @click="mainBgImgExisting = deleteImage(mainBgImgId, 'careerpage_screen_image') ? '' : mainBgImgExisting">Видалити</va-button></div>
                  </div>
                  <span>Завантажити нове зображення</span>
                  <va-file-upload
                    type="gallery"
                    dropzone
                    @input="delete errors.careerpage_screen_image"
                    v-model="mainBgImg"
                    style="margin-bottom: 25px;"
                  />
                  <div v-if="errors.careerpage_screen_image" style="color: red;">{{ errors.careerpage_screen_image[0] }}</div>
                  <va-button @click="uploadImage('careerpage_screen_image', 'careerpage.screen.image', mainBgImg[0])">Завантажити</va-button>
                </va-card>
                <va-input
                  label="Main screen title *"
                  v-model="mainTitle"
                  :messages="['The recommended number of characters is 15']"
                  :error="!!errors.careerpage_screen_title"
                  :error-messages="errors.careerpage_screen_title"
                  @input="delete errors.careerpage_screen_title"
                />
                <va-input
                  label="Main screen description *"
                  v-model="mainDescription"
                  :type="'textarea'"
                  :messages="['The recommended number of characters is 300']"
                  :error="!!errors.careerpage_screen_description"
                  :error-messages="errors.careerpage_screen_description"
                  @input="delete errors.careerpage_screen_description"
                />
                <va-input
                  label="Career button label *"
                  v-model="mainButton"
                  :messages="['The recommended number of characters is 191']"
                  :error="!!errors.careerpage_btn_label"
                  :error-messages="errors.careerpage_btn_label"
                  @input="delete errors.careerpage_btn_label"
                />
                <va-input
                  label="Scroll title *"
                  v-model="mainScroll"
                  :messages="['The recommended number of characters is 191']"
                  :error="!!errors.careerpage_scroll_title"
                  :error-messages="errors.careerpage_scroll_title"
                  @input="delete errors.careerpage_scroll_title"
                />
                <va-button
                  @click="validate()"
                >Save</va-button>
              </div>
              <div class="flex xs12" v-if="tabValue === 1">
                <va-input
                  label="Team block title *"
                  v-model="teamTitle"
                  :messages="['The recommended number of characters is 40']"
                  :error="!!errors.careerpage_team_screen_title"
                  :error-messages="errors.careerpage_team_screen_title"
                  @input="delete errors.careerpage_team_screen_title"
                />
                <va-input
                  label="Team block description *"
                  v-model="teamDescription"
                  :type="'textarea'"
                  :messages="['The recommended number of characters is 650']"
                  :error="!!errors.careerpage_team_screen_description"
                  :error-messages="errors.careerpage_team_screen_description"
                  @input="delete errors.careerpage_team_screen_description"
                />
                <va-input
                  label="Team block number *"
                  v-model="teamNumber"
                  :messages="['The recommended number of characters is 6']"
                  :error="!!errors.careerpage_team_screen_number_label"
                  :error-messages="errors.careerpage_team_screen_number_label"
                  @input="delete errors.careerpage_team_screen_number_label"
                />
                <va-input
                  label="Team block number description *"
                  v-model="teamNumberDescription"
                  :type="'textarea'"
                  :messages="['The recommended number of characters is 250']"
                  :error="!!errors.careerpage_team_screen_number_description"
                  :error-messages="errors.careerpage_team_screen_number_description"
                  @input="delete errors.careerpage_team_screen_number_description"
                />
                <va-card class="mb-3" title="Team screen first image *">
                  <div v-if="teamFirstImgExisting">
                    <a style="display: block;" target="_blank" :href="teamFirstImgExisting"><img style="max-width: 400px;" :src="teamFirstImgExisting"></a>
                    <div><va-button small color="danger" @click="teamFirstImgExisting = deleteImage(teamFirstImgId, 'careerpage_team_screen_first_image') ? '' : teamFirstImgExisting">Видалити</va-button></div>
                  </div>
                  <span>Завантажити нове зображення</span>
                  <va-file-upload
                    type="gallery"
                    dropzone
                    @input="delete errors.careerpage_team_screen_first_image"
                    v-model="teamFirstImg"
                    style="margin-bottom: 25px;"
                  />
                  <div v-if="errors.careerpage_team_screen_first_image" style="color: red;">{{ errors.careerpage_team_screen_first_image[0] }}</div>
                  <va-button @click="uploadImage('careerpage_team_screen_first_image', 'careerpage.team.screen.first_image', teamFirstImg[0])">Завантажити</va-button>
                </va-card>

                <va-card class="mb-3" title="Team screen second image *">
                  <div v-if="teamSecondImgExisting">
                    <a style="display: block;" target="_blank" :href="teamSecondImgExisting"><img style="max-width: 400px;" :src="teamSecondImgExisting"></a>
                    <div><va-button small color="danger" @click="teamSecondImgExisting = deleteImage(teamSecondImgId, 'careerpage_team_screen_second_image') ? '' : teamSecondImgExisting">Видалити</va-button></div>
                  </div>
                  <span>Завантажити нове зображення</span>
                  <va-file-upload
                    type="gallery"
                    dropzone
                    @input="delete errors.careerpage_team_screen_second_image"
                    v-model="teamSecondImg"
                    style="margin-bottom: 25px;"
                  />
                  <div v-if="errors.careerpage_team_screen_second_image" style="color: red;">{{ errors.careerpage_team_screen_second_image[0] }}</div>
                  <va-button @click="uploadImage('careerpage_team_screen_second_image', 'careerpage.team.screen.second_image', teamSecondImg[0])">Завантажити</va-button>
                </va-card>

                <va-button
                  @click="validate()"
                >Save</va-button>
              </div>
              <div class="flex xs12" v-if="tabValue === 2">
                <va-input
                  label="Department block title *"
                  v-model="departmentTitle"
                  :messages="['The recommended number of characters is 80']"
                  :error="!!errors.careerpage_department_title"
                  :error-messages="errors.careerpage_department_title"
                  @input="delete errors.careerpage_department_title"
                />
                <va-button
                  @click="validate()"
                >Save</va-button>
              </div>
              <div class="flex xs12" v-if="tabValue === 3">
                <va-input
                  label="Family block title *"
                  v-model="familyTitle"
                  :messages="['The recommended number of characters is 20']"
                  :error="!!errors.careerpage_family_title"
                  :error-messages="errors.careerpage_family_title"
                  @input="delete errors.careerpage_family_title"
                />
                <va-input
                  label="Family block description *"
                  v-model="familyDescription"
                  :type="'textarea'"
                  :messages="['The recommended number of characters is 250']"
                  :error="!!errors.careerpage_family_description"
                  :error-messages="errors.careerpage_family_description"
                  @input="delete errors.careerpage_family_description"
                />
                <va-card class="mb-3" title="Family image *">
                  <div v-if="familyImgExisting">
                    <a style="display: block;" target="_blank" :href="familyImgExisting"><img style="max-width: 400px;" :src="familyImgExisting"></a>
                    <div><va-button small color="danger" @click="familyImgExisting = deleteImage(familyImgId, 'careerpage_family_image') ? '' : familyImgExisting">Видалити</va-button></div>
                  </div>
                  <span>Завантажити нове зображення</span>
                  <va-file-upload
                    type="gallery"
                    dropzone
                    @input="delete errors.careerpage_family_image"
                    v-model="familyImg"
                    style="margin-bottom: 25px;"
                  />
                  <div v-if="errors.careerpage_family_image" style="color: red;">{{ errors.careerpage_family_image[0] }}</div>
                  <va-button @click="uploadImage('careerpage_family_image', 'careerpage_family_image', familyImg[0])">Завантажити</va-button>
                </va-card>
                <va-input
                  label="Family image info"
                  v-model="familyImgInfo"
                  :messages="['The recommended number of characters is 40']"
                  :error="!!errors.careerpage_family_image_info"
                  :error-messages="errors.careerpage_family_image_info"
                  @input="delete errors.careerpage_family_image_info"
                />
                <va-button
                  @click="validate()"
                >Save</va-button>
              </div>
              <div class="flex xs12" v-if="tabValue === 4">
                <va-input
                  label="Vacancy block title *"
                  v-model="vacancyTitle"
                  :messages="['The recommended number of characters is 191']"
                  :error="!!errors.careerpage_vacancy_title"
                  :error-messages="errors.careerpage_vacancy_title"
                  @input="delete errors.careerpage_vacancy_title"
                />
                <va-button
                  @click="validate()"
                >Save</va-button>
              </div>
              <div class="flex xs12" v-if="tabValue === 5">
                <va-input
                  label="Accede block title *"
                  v-model="accedeTitle"
                  :messages="['The recommended number of characters is 50']"
                  :error="!!errors.careerpage_accede_title"
                  :error-messages="errors.careerpage_accede_title"
                  @input="delete errors.careerpage_accede_title"
                />
                <va-input
                  label="Accede block description *"
                  v-model="accedeDescription"
                  :type="'textarea'"
                  :messages="['The recommended number of characters is 450']"
                  :error="!!errors.careerpage_accede_description"
                  :error-messages="errors.careerpage_accede_description"
                  @input="delete errors.careerpage_accede_description"
                />

                <va-card class="mb-3" title="Accede image *">
                  <div v-if="accedeImgExisting">
                    <a style="display: block;" target="_blank" :href="accedeImgExisting"><img style="max-width: 400px;" :src="accedeImgExisting"></a>
                    <div><va-button small color="danger" @click="accedeImgExisting = deleteImage(accedeImgId, 'careerpage_accede_image') ? '' : accedeImgExisting">Видалити</va-button></div>
                  </div>
                  <span>Завантажити нове зображення</span>
                  <va-file-upload
                    type="gallery"
                    dropzone
                    @input="delete errors.careerpage_accede_image"
                    v-model="accedeImg"
                    style="margin-bottom: 25px;"
                  />
                  <div v-if="errors.careerpage_accede_image" style="color: red;">{{ errors.careerpage_accede_image[0] }}</div>
                  <va-button @click="uploadImage('careerpage_accede_image', 'careerpage_accede_image', accedeImg[0])">Завантажити</va-button>
                </va-card>
                <va-input
                  label="Accede block button label *"
                  v-model="accedeButton"
                  :messages="['The recommended number of characters is 30']"
                  :error="!!errors.careerpage_accede_btn_label"
                  :error-messages="errors.careerpage_accede_btn_label"
                  @input="delete errors.careerpage_accede_btn_label"
                />
                <va-button
                  @click="validate()"
                >Save</va-button>
              </div>
              <div class="flex xs12" v-if="tabValue === 6">
                <va-input
                  label="internship block title *"
                  v-model="internshipTitle"
                  :messages="['The recommended number of characters is 50']"
                  :error="!!errors.careerpage_internship_title"
                  :error-messages="errors.careerpage_internship_title"
                  @input="delete errors.careerpage_internship_title"
                />
                <va-input
                  label="internship block description *"
                  v-model="internshipDescription"
                  :type="'textarea'"
                  :messages="['The recommended number of characters is 450']"
                  :error="!!errors.careerpage_internship_description"
                  :error-messages="errors.careerpage_internship_description"
                  @input="delete errors.careerpage_internship_description"
                />
                <va-card class="mb-3" title="internship image *">
                  <div v-if="internshipImgExisting">
                    <a style="display: block;" target="_blank" :href="internshipImgExisting"><img style="max-width: 400px;" :src="internshipImgExisting"></a>
                    <div><va-button small color="danger" @click="internshipImgExisting = deleteImage(internshipImgId, 'careerpage_internship_image') ? '' : internshipImgExisting">Видалити</va-button></div>
                  </div>
                  <span>Завантажити нове зображення</span>
                  <va-file-upload
                    type="gallery"
                    dropzone
                    @input="delete errors.careerpage_internship_image"
                    v-model="internshipImg"
                    style="margin-bottom: 25px;"
                  />
                  <div v-if="errors.careerpage_internship_image" style="color: red;">{{ errors.careerpage_internship_image[0] }}</div>
                  <va-button @click="uploadImage('careerpage_internship_image', 'careerpage_internship_image', internshipImg[0])">Завантажити</va-button>
                </va-card>
                <va-button
                  @click="validate()"
                >Save</va-button>
              </div>
              <div class="flex xs12" v-if="tabValue === 7">
                <va-input
                  label="offer block title *"
                  v-model="offerTitle"
                  :messages="['The recommended number of characters is 50']"
                  :error="!!errors.careerpage_offer_title"
                  :error-messages="errors.careerpage_offer_title"
                  @input="delete errors.careerpage_offer_title"
                />
                <va-input
                  label="offer block description *"
                  v-model="offerDescription"
                  :type="'textarea'"
                  :messages="['The recommended number of characters is 450']"
                  :error="!!errors.careerpage_offer_description"
                  :error-messages="errors.careerpage_offer_description"
                  @input="delete errors.careerpage_offer_description"
                />

                <va-card class="mb-3" title="offer image *">
                  <div v-if="offerImgExisting">
                    <a style="display: block;" target="_blank" :href="offerImgExisting"><img style="max-width: 400px;" :src="offerImgExisting"></a>
                    <div><va-button small color="danger" @click="offerImgExisting = deleteImage(offerImgId, 'careerpage_offer_image') ? '' : offerImgExisting">Видалити</va-button></div>
                  </div>
                  <span>Завантажити нове зображення</span>
                  <va-file-upload
                    type="gallery"
                    dropzone
                    @input="delete errors.careerpage_offer_image"
                    v-model="offerImg"
                    style="margin-bottom: 25px;"
                  />
                  <div v-if="errors.careerpage_offer_image" style="color: red;">{{ errors.careerpage_offer_image[0] }}</div>
                  <va-button @click="uploadImage('careerpage_offer_image', 'careerpage_offer_image', offerImg[0])">Завантажити</va-button>
                </va-card>

                <va-button
                  @click="validate()"
                >Save</va-button>
              </div>
              <div class="flex xs12" v-if="tabValue === 8">
                <va-input
                  label="join block title *"
                  v-model="joinTitle"
                  :messages="['The recommended number of characters is 50']"
                  :error="!!errors.careerpage_join_title"
                  :error-messages="errors.careerpage_join_title"
                  @input="delete errors.careerpage_join_title"
                />
                <va-input
                  label="join block description *"
                  v-model="joinDescription"
                  :type="'textarea'"
                  :messages="['The recommended number of characters is 450']"
                  :error="!!errors.careerpage_join_description"
                  :error-messages="errors.careerpage_join_description"
                  @input="delete errors.careerpage_join_description"
                />

                <va-card class="mb-3" title="join image *">
                  <div v-if="joinImgExisting">
                    <a style="display: block;" target="_blank" :href="joinImgExisting"><img style="max-width: 400px;" :src="joinImgExisting"></a>
                    <div><va-button small color="danger" @click="joinImgExisting = deleteImage(joinImgId, 'careerpage_join_image') ? '' : joinImgExisting">Видалити</va-button></div>
                  </div>
                  <span>Завантажити нове зображення</span>
                  <va-file-upload
                    type="gallery"
                    dropzone
                    @input="delete errors.careerpage_join_image"
                    v-model="joinImg"
                    style="margin-bottom: 25px;"
                  />
                  <div v-if="errors.careerpage_join_image" style="color: red;">{{ errors.careerpage_join_image[0] }}</div>
                  <va-button @click="uploadImage('careerpage_join_image', 'careerpage_join_image', joinImg[0])">Завантажити</va-button>
                </va-card>
                <va-button
                  @click="validate()"
                >Save</va-button>
              </div>
              <div class="flex xs12" v-if="tabValue === 9">
                <va-input
                  label="opportunities block title *"
                  v-model="oppTitle"
                  :messages="['The recommended number of characters is 50']"
                  :error="!!errors.careerpage_opportunities_title"
                  :error-messages="errors.careerpage_opportunities_title"
                  @input="delete errors.careerpage_opportunities_title"
                />
                <va-input
                  label="opportunities block description *"
                  v-model="oppDescription"
                  :type="'textarea'"
                  :messages="['The recommended number of characters is 450']"
                  :error="!!errors.careerpage_opportunities_description"
                  :error-messages="errors.careerpage_opportunities_description"
                  @input="delete errors.careerpage_opportunities_description"
                />
                <va-card class="mb-3" title="opportunities image *">
                  <div v-if="oppImgExisting">
                    <a style="display: block;" target="_blank" :href="oppImgExisting"><img style="max-width: 400px;" :src="oppImgExisting"></a>
                    <div><va-button small color="danger" @click="oppImgExisting = deleteImage(oppImgId, 'careerpage_opportunities_image') ? '' : oppImgExisting">Видалити</va-button></div>
                  </div>
                  <span>Завантажити нове зображення</span>
                  <va-file-upload
                    type="gallery"
                    dropzone
                    @input="delete errors.careerpage_opportunities_image"
                    v-model="oppImg"
                    style="margin-bottom: 25px;"
                  />
                  <div v-if="errors.careerpage_opportunities_image" style="color: red;">{{ errors.careerpage_opportunities_image[0] }}</div>
                  <va-button @click="uploadImage('careerpage_opportunities_image', 'careerpage_opportunities_image', oppImg[0])">Завантажити</va-button>
                </va-card>
                <va-button
                  @click="validate()"
                >Save</va-button>
              </div>
            </div>
          </div>
        </div>
      </va-card>
    </div>
  </div>
</template>

<script>
import * as axios from 'axios'
import Vue from 'vue'

export default {
  name: 'grid',
  data () {
    return {
      mainBgImg: [],
      mainBgImgId: '',
      mainBgImgExisting: '',
      mainTitle: '',
      mainDescription: '',
      mainButton: '',
      mainScroll: '',

      teamTitle: '',
      teamDescription: '',
      teamNumber: '',
      teamNumberDescription: '',
      teamFirstImg: [],
      teamFirstImgId: '',
      teamFirstImgExisting: '',
      teamSecondImg: [],
      teamSecondImgId: '',
      teamSecondImgExisting: '',

      departmentTitle: '',

      familyTitle: '',
      familyDescription: '',
      familyImg: [],
      familyImgId: '',
      familyImgExisting: '',
      familyImgInfo: '',

      vacancyTitle: '',

      accedeTitle: '',
      accedeDescription: '',
      accedeImg: [],
      accedeImgId: '',
      accedeImgExisting: '',
      accedeButton: '',

      internshipTitle: '',
      internshipDescription: '',
      internshipImg: [],
      internshipImgId: '',
      internshipImgExisting: '',

      offerTitle: '',
      offerDescription: '',
      offerImg: [],
      offerImgId: '',
      offerImgExisting: '',

      joinTitle: '',
      joinDescription: '',
      joinImg: [],
      joinImgId: '',
      joinImgExisting: '',

      oppTitle: '',
      oppDescription: '',
      oppImg: [],
      oppImgId: '',
      oppImgExisting: '',

      errors: [],

      tabTitles: ['Main', 'Team', 'Department', 'Family', 'Vacancy', 'Accede', 'Internship', 'Offer', 'Join Team', 'Opportunities'],
      tabValue: 0,
    }
  },
  created () {
    this.fetch()
  },
  computed: {
    computedStyle () {
      return {
        backgroundColor: this.$themes.primary,
      }
    },
    locale () {
      return Vue.i18n.locale()
    },
    putData () {
      return {
        careerpage_screen_title: this.mainTitle,
        careerpage_screen_description: this.mainDescription,
        careerpage_btn_label: this.mainButton,
        careerpage_scroll_title: this.mainScroll,

        careerpage_team_screen_title: this.teamTitle,
        careerpage_team_screen_description: this.teamDescription,
        careerpage_team_screen_number_label: this.teamNumber,
        careerpage_team_screen_number_description: this.teamNumberDescription,

        careerpage_department_title: this.departmentTitle,

        careerpage_family_title: this.familyTitle,
        careerpage_family_description: this.familyDescription,
        careerpage_family_image_info: this.familyImgInfo,

        careerpage_vacancy_title: this.vacancyTitle,

        careerpage_accede_title: this.accedeTitle,
        careerpage_accede_description: this.accedeDescription,
        careerpage_accede_btn_label: this.accedeButton,

        careerpage_internship_title: this.internshipTitle,
        careerpage_internship_description: this.internshipDescription,

        careerpage_offer_title: this.offerTitle,
        careerpage_offer_description: this.offerDescription,

        careerpage_join_title: this.joinTitle,
        careerpage_join_description: this.joinDescription,

        careerpage_opportunities_title: this.oppTitle,
        careerpage_opportunities_description: this.oppDescription,
      }
    },
  },
  watch: {
    locale: function (value) {
      this.fetch()
    },
  },
  methods: {
    uploadImage (param, key, file) {
      if (!file) {
        return
      }
      const formData = new FormData()
      formData.append(param, file)
      formData.append('lang', this.locale)
      formData.append('key', key)
      formData.append('multiple', '0')
      formData.append('file_id', '0')
      axios.post(`${process.env.VUE_APP_AUTOSTRADA_API_URL}/${this.locale}/admin/pages/upload/3`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
        .then(response => {
          this.showToast('Successful upload')
        })
        .catch(error => {
          console.log(error)
          this.showToast('Error')
        })
    },
    async deleteImage (id, fieldKey) {
      return axios.post(`${process.env.VUE_APP_AUTOSTRADA_API_URL}/${this.locale}/admin/pages/3/delete-file/${id}`, {
        key: fieldKey,
      })
        .then(() => {
          this.showToast('Successfully deleted')
        })
        .catch(() => {
          this.showToast('Error')
        })
    },
    isTabInvalid (name) {
      const tab = this.tabTitles.findIndex(item => item === name)

      if (tab === 0) {
        return !!this.errors.careerpage_screen_title || !!this.errors.careerpage_screen_description || !!this.errors.careerpage_btn_label || !!this.errors.careerpage_scroll_title || !!this.errors.careerpage_screen_image
      } else if (tab === 1) {
        return !!this.errors.careerpage_team_screen_title || !!this.errors.careerpage_team_screen_description || !!this.errors.careerpage_team_screen_number_label ||
          !!this.errors.careerpage_team_screen_number_description || !!this.errors.careerpage_team_screen_first_image || !!this.errors.careerpage_team_screen_second_image
      } else if (tab === 2) {
        return !!this.errors.careerpage_department_title
      } else if (tab === 3) {
        return !!this.errors.careerpage_family_title || !!this.errors.careerpage_family_description || !!this.errors.careerpage_family_image_info || !!this.errors.careerpage_family_image
      } else if (tab === 4) {
        return !!this.errors.careerpage_vacancy_title
      } else if (tab === 5) {
        return !!this.errors.careerpage_accede_title || !!this.errors.careerpage_accede_description || !!this.errors.careerpage_accede_btn_label || !!this.errors.careerpage_accede_image
      } else if (tab === 6) {
        return !!this.errors.careerpage_internship_title || !!this.errors.careerpage_internship_description || !!this.errors.careerpage_internship_image
      } else if (tab === 7) {
        return !!this.errors.careerpage_offer_title || !!this.errors.careerpage_offer_description || !!this.errors.careerpage_offer_image
      } else if (tab === 8) {
        return !!this.errors.careerpage_join_title || !!this.errors.careerpage_join_description || !!this.errors.careerpage_join_image
      } else if (tab === 9) {
        return !!this.errors.careerpage_opportunities_title || !!this.errors.careerpage_opportunities_description || !!this.errors.careerpage_opportunities_image
      }
      return false
    },
    submit () {
      axios.put(`${process.env.VUE_APP_AUTOSTRADA_API_URL}/${this.locale}/admin/pages/3`, this.putData)
        .then(response => {
          this.showToast('Successful update')
        })
        .catch(error => {
          console.log(error)
          if (error.response.status === 422) {
            this.errors = error.response.data.errors
          }
          this.showToast('Error')
        })
    },
    validate () {
      axios.put(`${process.env.VUE_APP_AUTOSTRADA_API_URL}/${this.locale}/admin/pages/validate/3`, this.putData, { headers: { 'X-Requested-With': 'XMLHttpRequest' } })
        .then(response => {
          this.errors = response.data
          if (!Object.keys(this.errors).length) {
            this.submit()
          }
        })
        .catch(error => {
          console.log(error)
          this.showToast('Validate Fetch Error')
        })
    },
    fetch () {
      axios.get(`${process.env.VUE_APP_AUTOSTRADA_API_URL}/${this.locale}/admin/pages/3`)
        .then(response => {
          // console.log(response.data)
          this.mainTitle = response.data.careerpage_screen_title
          this.mainDescription = response.data.careerpage_screen_description
          this.mainButton = response.data.careerpage_btn_label
          this.mainScroll = response.data.careerpage_scroll_title

          this.teamTitle = response.data.careerpage_team_screen_title
          this.teamDescription = response.data.careerpage_team_screen_description
          this.teamNumber = response.data.careerpage_team_screen_number_label
          this.teamNumberDescription = response.data.careerpage_team_screen_number_description

          this.departmentTitle = response.data.careerpage_department_title

          this.familyTitle = response.data.careerpage_family_title
          this.familyDescription = response.data.careerpage_family_description
          this.familyImgInfo = response.data.careerpage_family_image_info

          this.vacancyTitle = response.data.careerpage_vacancy_title

          this.accedeTitle = response.data.careerpage_accede_title
          this.accedeDescription = response.data.careerpage_accede_description
          this.accedeButton = response.data.careerpage_accede_btn_label

          this.internshipTitle = response.data.careerpage_internship_title
          this.internshipDescription = response.data.careerpage_internship_description

          this.offerTitle = response.data.careerpage_offer_title
          this.offerDescription = response.data.careerpage_offer_description

          this.joinTitle = response.data.careerpage_join_title
          this.joinDescription = response.data.careerpage_join_description

          this.oppTitle = response.data.careerpage_opportunities_title
          this.oppDescription = response.data.careerpage_opportunities_description

          this.mainBgImgExisting = response.data.careerpage_screen_image ? process.env.VUE_APP_AUTOSTRADA_ADMIN + response.data.careerpage_screen_image : ''
          this.mainBgImgId = response.data.careerpage_screen_image_id
          this.teamFirstImgExisting = response.data.careerpage_team_screen_first_image ? process.env.VUE_APP_AUTOSTRADA_ADMIN + response.data.careerpage_team_screen_first_image : ''
          this.teamFirstImgId = response.data.careerpage_team_screen_first_image_id
          this.teamSecondImgExisting = response.data.careerpage_team_screen_second_image ? process.env.VUE_APP_AUTOSTRADA_ADMIN + response.data.careerpage_team_screen_second_image : ''
          this.teamSecondImgId = response.data.careerpage_team_screen_second_image_id
          this.familyImgExisting = response.data.careerpage_family_image ? process.env.VUE_APP_AUTOSTRADA_ADMIN + response.data.careerpage_family_image : ''
          this.familyImgId = response.data.careerpage_family_image_id
          this.accedeImgExisting = response.data.careerpage_accede_image ? process.env.VUE_APP_AUTOSTRADA_ADMIN + response.data.careerpage_accede_image : ''
          this.accedeImgId = response.data.careerpage_accede_image_id
          this.internshipImgExisting = response.data.careerpage_internship_image ? process.env.VUE_APP_AUTOSTRADA_ADMIN + response.data.careerpage_internship_image : ''
          this.internshipImgId = response.data.careerpage_internship_image_id
          this.offerImgExisting = response.data.careerpage_offer_image ? process.env.VUE_APP_AUTOSTRADA_ADMIN + response.data.careerpage_offer_image : ''
          this.offerImgId = response.data.careerpage_offer_image_id
          this.joinImgExisting = response.data.careerpage_join_image ? process.env.VUE_APP_AUTOSTRADA_ADMIN + response.data.careerpage_join_image : ''
          this.joinImgId = response.data.careerpage_join_image_id
          this.oppImgExisting = response.data.careerpage_opportunities_image ? process.env.VUE_APP_AUTOSTRADA_ADMIN + response.data.careerpage_opportunities_image : ''
          this.oppImgId = response.data.careerpage_opportunities_image_id
        })
        .catch(error => {
          console.log(error.response)
        })
    },
  },
}
</script>

<style lang="scss">
.grid {
  &__container {
    min-height: 3rem;
    color: $white;
    border-radius: 0.5rem;
  }
}

.va-file-upload-gallery-item {
  flex-basis: unset;
  max-width: 400px;
  width: unset;
}

.va-tab {
  &.is-invalid {
    opacity: 1 !important;
    color: red !important;
  }
}
</style>
